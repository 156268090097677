






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "name",
          "sort-desc": false,
        },
        content: {
          name: "Quản lý phân loại sản phẩm",
          urlEnabled: true,
          search: {},
          displayFields: {
            name: {
              text: "Tên",
              sortable: true,
            },
            description: {
              text: "Mô tả",
              sortable: true,
            },
            tags: {
              text: "Tags",
              sortable: true,
              options: {
                labels: true,
              },
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {},
          },
          topActionButtons: {
            insert: {},
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["admin", "kt"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("productCategories", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call("productCategories", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "productCategories",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("productCategories", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Tên",
                  required: true,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              tags: {
                type: "textMultiple",
                attrs: {
                  label: "Tags",
                },
              },
            },
            insertForm: userManager.checkRole(["admin", "kt"]) && {
              content: {
                fieldNames: ["name", "description", "tags"],
              },
            },
            editForm: userManager.checkRole(["admin", "kt"]) && {
              content: {
                fieldNames: ["name", "description", "tags"],
              },
            },
            delete: userManager.checkRole(["admin"]) && {
              confirmDisplayField: "name",
            },
          },
        },
      },
    };
  },
});
